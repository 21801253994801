/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
require("typeface-karla")

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1)
}
